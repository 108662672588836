<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row style="margin-bottom: 20px;">
					<a-col :span="24">
						<a-month-picker v-model="date" @change="onChange3" :allowClear="false"
						    style="width: 120px;margin-right: 10px;" />
					</a-col>
				</a-row>
				<a-spin :spinning="isLoading">
					<div id="data" style="height: 0px;text-align: center;font-size: 2rem;line-height: 100px;"></div>
					<div style="height: 720px;width: 100%;text-align: left" id="mainScrapCode">

					</div>
				</a-spin>
			</a-card>
		</a-config-provider>
	</div>
</template>
<script>
	import {
		AppComponentBase
	} from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import {
		KanBanServiceProxy
	} from '../../../shared/service-proxies';
	import moment from "moment";
	import * as echarts from 'echarts';

	let _this;
	export default {
		name: "Departmental-hidden-close",
		mixins: [AppComponentBase],
		components: {

		},
		created() {
			_this = this;
			this.KanBanServiceProxy = new KanBanServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.function();
		},
		data() {
			return {
				zh_CN,
				isLoading: true, //加载中
				date: undefined,
				nowTime: [],
				
				chartData: [],
				data: undefined,
				departmentList: [],
			}
		},
		computed: {
			// 指定图表的配置项和数据
			option() {
				return {
					title: {
						text: this.l('隐患问题关闭率'),
						subtext: '',
						x: 'center',
						textStyle: {
							fontSize: 26,
						},
					},
					tooltip: {
						trigger: 'axis',
					},
					legend: {
						// data: [this.l('FindNum'), this.l('CloseNum'), this.l('CloseRate')],
						top: '97%',
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						name: '',
						data: this.departmentList,
						splitLine: {
							show: true
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('Count'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
							}
						},
						{
							type: 'value',
							name: this.l('关闭率') + '(%)',
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}%',
							},
							splitLine:{
							    show:true,
							    lineStyle:{
							        type:'dashed'
							    }
							}
						}
					],
					series: this.chartData,
				}
			}
		},
		methods: {
			moment,
			function() {
				this.date = moment(new Date());
				this.getDataMaintenance();
			},
			getData() {
				this.isLoading = true;
				this.KanBanServiceProxy.getDeptIssueCloseRateForSafetyHazards(
					this.date
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					this.data = res;
					this.departmentList = [];
					for(let item of res.deptList) {
						this.departmentList.push(item.name);
					}
					this.chartsData();
				})
			},

			chartsData() {
				let number = {
					name: this.l('问题'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let target = {
					name: this.l('关闭'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let closeRate = {
					name: this.l('关闭率'),
					type: 'line',
					yAxisIndex : 1,
					label: {
						show: true,
						textBorderWidth: '0',
						formatter: function(data){
							if(data.value>0) {
								return data.value + '%'
							}else {
								return ""
							}
						}
					}
				};
				let targetCloseRate = {
					name: this.l('目标关闭率'),
					type: 'line',
					yAxisIndex : 1,
					label: {
						show: true,
						textBorderWidth: '0',
						formatter: function(data){
							if(data.value>0) {
								return data.value + '%'
							}else {
								return ""
							}
						}
					}
				};
				
				let targetAllList = [];
				for(let item of this.fakeData) {
					if(item.code === "Hazard-closure-rate") {
						targetAllList = JSON.parse(item.value)
					}
				}
				let targetList = [];
				for(let item of targetAllList) {
					if(item.date === moment(this.date).format('YYYY-MM')) {
						console.log(item)
						targetList.push(item)
					}
				}
				let targetData = [];
				for(let item of this.departmentList) {
					let isContain = targetList.find(items => items.departmentName == item);
					if(isContain) {
						targetData.push(Number(isContain.TargetValue));
					}else {
						targetData.push(0);
					}
				}
				number.data = this.data.issueCountList;
				target.data = this.data.closeCountList;
				closeRate.data = this.data.closeRateList;
				targetCloseRate.data = targetData;
				this.chartData = [];
				this.chartData.push(number);
				this.chartData.push(target);
				this.chartData.push(closeRate);
				this.chartData.push(targetCloseRate);
				this.charts();
			},
			
			//维护的数据
			getDataMaintenance() {
				this.KanBanServiceProxy.getAll(
					
				).finally(() => {
				    
				}).then(res => {
					this.fakeData = res;
					this.getData();
				})
			},

			charts() {
				this.myeChart = echarts.init(document.getElementById("mainScrapCode"))

				this.myeChart.clear();
				
				document.getElementById("data").style.height = "0px";
				document.getElementById("data").innerHTML = "";
				
				// 使用刚指定的配置项和数据显示图表。
				document.getElementById("mainScrapCode").style.height = "720px";
				this.myeChart.setOption(this.option);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			onChange3(date, time) {
			    this.date = date;
			    this.getData();
			}
		}
	}
</script>

<style>
</style>
